"use client";

import { useEffect, useRef } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

export default function AOSinit(config: AOS.AosOptions) {

  const refTimeout = useRef<number | null>(null);
  useEffect(() => {
    refTimeout.current = window.setTimeout(() => {
      AOS.init(config);
    }, 50);

    return () => {
      if (refTimeout.current) {
        window.clearTimeout(refTimeout.current);
      }
    }
  }, [config]);
  return <></>;
}