// generate random UUID
const uuid = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = Math.random() * 16 | 0,
            v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

const removeSpecialCharacters = (text: string): string => {
    const normalizedText = text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    return normalizedText.replace(/[^a-zA-Z0-9\sñÑ]/g, '');
};


export {
    uuid,
    removeSpecialCharacters
}