import { client } from "@/sanity/lib/client";
import { uuid } from "./common";

export type IUser = {
    name: string,
    message: string,
    email: string,
    phone: string,
}

const createUser = async (user: IUser) => {
    const { name, email, phone, message } = user;
    const data = await client.createIfNotExists({
        name,
        email,
        message,
        phone,
        date: new Date().toLocaleString(),
        _type: 'user',
        _id: uuid(),
    });
    return data as IUser;
}


export {
    createUser,
}