"use client";

import {
  Controller,
  FieldValues,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import {
  Textarea,
  Input,
  Checkbox,
  Button,
  Modal,
  ModalContent,
  ModalBody,
  useDisclosure,
} from "@nextui-org/react";
import Link from "next/link";
import { createUser, IUser } from "@/src/services/sanity/user";

export default function RegisterUser() {
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({ mode: "onChange" });
  const { isOpen, onOpenChange, onOpen } = useDisclosure();

  const onSubmit: SubmitHandler<FieldValues> = async (data) => {
    try {
      const { name, email, phone, message } = data as IUser;
      createUser({ email, name, message, phone })
        .then(() => {
          sendEmail(data);
        })
        .catch((err) => {
          console.error(err);
        });
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };

  const sendEmail = async (data: unknown) => {
    await fetch("/api/contact", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        return res.json();
      })
      .then(() => {
        onOpen();
        reset();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  let literalsErrors = {
    name: {
      required: "Este campo es requerido",
    },
    email: {
      required: "Este campo es requerido",
      pattern: "El correo electrónico no es válido",
    },
    phone: {
      required: "Este campo es requerido",
      pattern: "El número de teléfono no es válido",
    },
    privacity: {
      required: "Debes aceptar la política de privacidad",
    },
  };

  return (
    <>
      <form
        className="mt-5"
        onSubmit={handleSubmit(onSubmit)}
        aria-labelledby="contact-form-heading"
      >
        <header className="sr-only">
          <h2 id="contact-form-heading">Formulario de Contacto</h2>
        </header>
        <div className="relative">
          <label htmlFor="name" className="sr-only">
            Nombre
          </label>
          <Input
            {...register("name", { required: literalsErrors.name.required })}
            fullWidth
            defaultValue=""
            type="text"
            className="my-2 border-white text-xl focus:outline-none rounded-none"
            label="Nombre"
            id="name"
          />
          {errors.name?.message && (
            <span className="absolute -bottom-3 flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
              {errors.name?.message.toString()}
            </span>
          )}
        </div>
        <div className="flex flex-col items-center lg:flex-row">
          <div className="relative w-full md:w-3/4 md:mr-5">
            <label htmlFor="email" className="sr-only">
              Correo Electrónico
            </label>
            <Input
              {...register("email", {
                required: literalsErrors.email.required,
                pattern: {
                  value: /^\S+@\S+$/i,
                  message: literalsErrors.email.pattern,
                },
              })}
              fullWidth
              defaultValue=""
              label="Correo Electrónico"
              type="email"
              className="mt-1 py-2 bg-transparent focus:outline-none"
              id="email"
            />
            {errors.email?.message && (
              <span className="absolute -bottom-3 flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                {errors.email?.message.toString()}
              </span>
            )}
          </div>
          <div className="relative w-full md:w-3/4">
            <label htmlFor="phone" className="sr-only">
              Teléfono
            </label>
            <Input
              {...register("phone", {
                required: literalsErrors.phone.required,
                pattern: {
                  value: /^[0-9]{9}$/,
                  message: literalsErrors.phone.pattern,
                },
              })}
              fullWidth
              defaultValue=""
              label="Teléfono"
              className="mt-1 py-2 bg-transparent"
              id="phone"
              inputMode="numeric"
              pattern="[0-9]"
            />
            {errors.phone?.message && (
              <span className="absolute -bottom-3 flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                {errors.phone?.message.toString()}
              </span>
            )}
          </div>
        </div>
        <div>
          <label htmlFor="message" className="sr-only">
            Mensaje
          </label>
          <Textarea
            {...register("message")}
            defaultValue=""
            className="mt-1 py-2 bg-transparent focus:outline-none"
            label="Mensaje"
            id="message"
          ></Textarea>
        </div>
        <div className="flex items relative" data-aos="fade-up">
          <Controller
            name="privacity"
            control={control}
            render={({ field: { onChange, value } }) => (
              <>
                <Checkbox
                  {...register("privacity", {
                    required: literalsErrors.privacity.required,
                  })}
                  defaultSelected={value}
                  color="default"
                  required
                  id="privacity"
                  onChange={onChange}
                  isSelected={value}
                  className="mr-2 my-1"
                >
                  <span className="text-gray-500 text-sm text-left">
                    Acepto la{" "}
                    <Link
                      href="/legal"
                      className="text-sm text-gray-500 underline"
                    >
                      Política de privacidad
                    </Link>
                  </span>
                </Checkbox>
                {errors.privacity?.message && (
                  <span className="absolute -bottom-5 flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                    {errors.privacity?.message.toString()}
                  </span>
                )}
              </>
            )}
          />
        </div>
        <div
          className="relative pt-5 group flex justify-start"
          data-aos="fade-up"
        >
          <Button
            as={'button'}
            type="submit"
            className="my-4 p-6 w-full flex items-center justify-between border border-white text-white rounded-none bg-transparent hover:bg-white hover:text-black transition-colors duration-300"
            color="primary"
            onClick={handleSubmit(onSubmit)}
            aria-label="Enviar formulario"
          >
            <span className="flex-grow text-start">ENVIAR FORMULARIO</span>
            <svg
              className="w-6 h-6 fill-current transform-gpu transition-transform duration-300 hover:translate-x-4"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              aria-hidden="true"
            >
              <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
            </svg>
          </Button>
        </div>
      </form>
      <Modal className="bg-white" isOpen={isOpen} onOpenChange={onOpenChange}>
        <ModalContent>
          {(onClose) => (
            <ModalBody>
              <EmailConfirmation />
            </ModalBody>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}

const EmailConfirmation = () => {
  return (
    <div
      className="funds-success-message-container py-4 px-2 "
      role="alert"
      aria-labelledby="success-message-heading"
    >
      <div className="funds-checkmark-text-container">
        <div className="funds-checkmark-container">
          <svg
            className="funds-checkmark"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 52 52"
            aria-hidden="true"
          >
            <circle
              className="funds-checkmark-circle"
              cx="26"
              cy="26"
              r="25"
              fill="none"
            />
            <path
              className="funds-checkmark-check"
              fill="none"
              d="M14.1 27.2l7.1 7.2 16.7-16.8"
            />
          </svg>

          <div className="funds-display-on-ie">
            <svg
              className="funds-ie-checkmark"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 52 52"
              aria-hidden="true"
            >
              <circle
                className="funds-ie-checkmark-circle"
                cx="26"
                cy="26"
                r="25"
                fill="none"
              />
              <path
                className="funds-ie-checkmark-check"
                fill="none"
                d="M14.1 27.2l7.1 7.2 16.7-16.8"
              />
            </svg>
          </div>
        </div>
      </div>

      <div className="funds-success-message ">
        <h2 id="success-message-heading" className="mb-4">
        ¡Gracias por contactar!
        </h2>
        <p>
        Hemos recibido tu solicitud. Nos pondremos en contacto lo antes posible. Estamos para ayudarte.
        </p>
        
      </div>
    </div>
  );
};
